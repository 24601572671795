import React, { useRef, useState } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";

import { sendContactEmail } from "../utils/emailer";

function Contact() {
  const nameRef = useRef("");
  const emailRef = useRef("");
  const messageRef = useRef("");
  const [variant, setVariant] = useState("");
  const [feedback, setFeedback] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const setAlert = (res) => {
    if (res.status === 200) {
      setVariant("success");
      setFeedbackMessage("Thank you for contacting us!");
    } else {
      setVariant("danger");
      setFeedbackMessage("Message failed to send, please try again");
    }
    setFeedback(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setFeedback(false);
      setIsLoading(true);

      const res = await sendContactEmail(
        nameRef.current.value,
        emailRef.current.value,
        messageRef.current.value
      );

      setAlert(res);
      setIsLoading(false);
    } catch {
      setAlert();
      setIsLoading(false);
    }

    e.target.reset();
  };

  return (
    <>
      {feedback && <Alert variant={variant}>{feedbackMessage}</Alert>}
      <Card>
        <Card.Body>
          <h2>Contact Us</h2>
          <Form onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
              <input
                type="text"
                ref={nameRef}
                className="form-control"
                id="floatingInput"
                placeholder="Your name"
                required
              />
              <label htmlFor="floatingInput">Name</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="email"
                ref={emailRef}
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                required
              />
              <label htmlFor="floatingInput">Email address</label>
            </div>

            <div className="form-floating">
              <textarea
                ref={messageRef}
                className="form-control"
                placeholder="Leave a message here"
                id="floatingTextarea"
                style={{ height: "140px" }}
                required
              />
              <label htmlFor="floatingTextarea">Message</label>
            </div>

            <Button
              disabled={isLoading}
              className="w-100 mt-3"
              variant="primary"
              type="submit"
            >
              Send Message
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default Contact;
