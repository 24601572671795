import React from 'react';
import MainNavigation from './MainNavigation';
import { Container } from 'react-bootstrap';

import classes from './Layout.module.css';

function Layout(props) {
  return (
    <div className={classes.customBg}>
      <MainNavigation />
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <main>{props.children}</main>
      </Container>
    </div>
  );
}

export default Layout;
