import React from 'react';

import Hero from '../components/hero/hero';

const Home = () => {
  return (
    <div>
      <Hero />
      <div style={{ marginTop: '1rem' }}>
        <h1>We are Aston Brothers Eggs</h1>
        <p>
          Aston Bros. Eggs is a small egg business operated by two autistic
          brothers (with some help from Dad), Timoteo and Jaime Aston. What
          started as a method to teach the value of hard work and money
          management has grown into something a bit bigger and more enduring.
          The chickens are all pasture raised, enjoying their days clucking and
          scratching about in their yard. Because of this, the eggs are rich and
          full of flavor. All feed and supplies for the business are locally
          sourced. Aston Bros. has two drop off locations at present, servicing
          Oak Harbor with limited (Sunday only) service to Arlington.
        </p>
      </div>
    </div>
  );
};

export default Home;
