import { React, createContext, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import app from '../utils/firebaseClient';
import 'firebase/firestore';

const OrdersContext = createContext({
  orders: [],
  totalOrders: 0,
  createOrder: (order, stripeId) => {},
  fulfillOrder: orderId => {},
  deleteOrder: orderId => {},
});

export function OrdersProvider(props) {
  const [newOrders, setNewOrders] = useState([]);

  // Read all new orders from the database
  useEffect(() => {
    app
      .firestore()
      .collection('orders')
      .where('status', 'in', ['cash', 'paid'])
      .onSnapshot(snapshot => {
        let ordersToList = [];
        snapshot.docs.forEach(doc => {
          let order = doc.data();
          order.id = doc.id;
          ordersToList.push(order);
        });
        setNewOrders(ordersToList);
      });
  }, []);

  // Create new order
  async function handleCreateNewOrder(order, stripeId) {
    if (stripeId) {
      await app
        .firestore()
        .collection('orders')
        .doc(stripeId)
        .set(order)
        .then(docRef => {
          console.log('Order accepted: ', docRef);
        })
        .catch(error => {
          console.error('Something went wrong', error);
          alert(error);
        });
    } else {
      await app
        .firestore()
        .collection('orders')
        .add(order)
        .then(docRef => {
          console.log('Order accepted: ', docRef);
        })
        .catch(error => {
          console.error('Something went wrong', error);
          alert(error);
        });
    }

    // // Update available eggs by the dozen when order submitted
    if (order.payment === 'cash') {
      try {
        const eggRef = app.firestore().collection('eggs').doc('egg');
        await eggRef.update({
          currentCount: firebase.firestore.FieldValue.increment(
            -order.dozen * 12
          ),
        });
      } catch (err) {
        console.log(err.stack);
      }
    }
  }

  // Complete/fulfill an order
  async function handleFulfillOrder(orderId) {
    try {
      await app
        .firestore()
        .collection('orders')
        .doc(orderId)
        .update({ isComplete: true });

      setNewOrders(prevNewOrders => {
        return prevNewOrders.filter(order => order.id !== orderId);
      });
    } catch (err) {
      console.log(err.stack);
    }
  }

  // Delete an order from Firebase
  async function handleDeleteOrder(orderId) {
    await app.firestore().collection('orders').doc(orderId).delete();

    setNewOrders(prevNewOrders => {
      return prevNewOrders.filter(order => order.id !== orderId);
    });
  }

  // Define the order context
  const context = {
    orders: newOrders,
    totalOrders: newOrders.length,
    createOrder: handleCreateNewOrder,
    fulfillOrder: handleFulfillOrder,
    deleteOrder: handleDeleteOrder,
  };

  return (
    <OrdersContext.Provider value={context}>
      {props.children}
    </OrdersContext.Provider>
  );
}

export default OrdersContext;

export const useOrders = () => useContext(OrdersContext);
