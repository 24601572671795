export const sendOrderSendEmail = (name, email, dozen, location, payment) => {
  return fetch('https://stark-peak-71711.herokuapp.com/api/order-email', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      email,
      dozen,
      location,
      payment,
    }),
  });
};

export const sendContactEmail = async (name, email, message) => {
  return await fetch(
    'https://stark-peak-71711.herokuapp.com/api/contact-email',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email, message }),
    }
  );
};
