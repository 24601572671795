import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useState, useEffect } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';

import 'firebase/firestore';
import app from '../utils/firebaseClient';

import { useOrders } from '../utils/ordersProvider';
import { sendOrderSendEmail } from '../utils/emailer';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Order = () => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [count, setCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [dozen, setDozen] = useState('');
  const [payment, setPayment] = useState('');
  const [variant, setVariant] = useState('');
  const [location, setLocation] = useState('');
  const [feedback, setFeedback] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const { createOrder } = useOrders();

  useEffect(() => {
    app
      .firestore()
      .collection('eggs')
      .doc('egg')
      .onSnapshot(doc => {
        const { currentCount } = doc.data();
        const availableDozens = currentCount / 12;
        setCount(Math.floor(availableDozens));
      });
  });

  useEffect(() => {
    app
      .firestore()
      .collection('eggs')
      .doc('egg')
      .onSnapshot(doc => {
        const { pricePerDozen } = doc.data();
        setPrice(pricePerDozen);
      });
  });

  let submitButton;
  if (isLoading) {
    submitButton = (
      <button
        className="btn btn-primary w-100 mt-3"
        type="button"
        disabled
      >
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        Processing Order...
      </button>
    );
  } else {
    submitButton = (
      <Button
        disabled={!(name, email, phone, dozen, location, payment)}
        className="w-100 mt-3"
        variant="primary"
        type="submit"
      >
        Place Order
      </Button>
    );
  }

  const setAlert = (status, message) => {
    setVariant(status);
    setFeedbackMessage(message);
    setFeedback(true);
  };

  const processOrder = async stripeId => {
    let paidStatus = '';
    if (payment === 'card') {
      paidStatus = 'card';
    } else if (payment === 'cash') {
      paidStatus = 'cash';
    }

    const order = {
      name,
      email,
      phone,
      dozen,
      location,
      payment,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      status: paidStatus,
    };

    if (stripeId) {
      await createOrder(order, stripeId);
    } else {
      await createOrder(order);
    }
  };

  const handleStripePayment = async () => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await fetch(
      'https://stark-peak-71711.herokuapp.com/api/create-checkout-session',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          dozen,
        }),
      }
    );

    const session = await response.json();
    await processOrder(session.id);

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      setAlert('danger', result.error.message);
    }
  };

  const handleSubmit = async e => {
    setFeedback(false);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    e.preventDefault();
    setValidated(true);
    setIsLoading(true);

    if (payment === 'card') {
      setAlert('success', 'Thank you for you order! Redirecting to checkout.');
      await handleStripePayment();
    } else if (payment === 'paypal') {
      console.log('paypal');
    } else if (payment === 'cash') {
      setAlert('success', 'Thank you for your order!');
      await processOrder();
      await sendOrderSendEmail(name, email, dozen, location, payment);
      history.push('/received');
    }

    //e.target.reset();
    setIsLoading(false);
  };

  return (
    <div style={{ marginTop: 70 }}>
      {feedback && <Alert variant={variant}>{feedbackMessage}</Alert>}
      <Card>
        <Card.Header as="h2">Order Eggs Here!</Card.Header>
        <Card.Body>
          <Card.Text>
            There are <strong>{count > 0 ? count : 0}</strong> dozen eggs
            available.
          </Card.Text>
          <Card.Text>
            Today's price per dozen is: <strong>${price.toFixed(2)}</strong>
          </Card.Text>

          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Full Name"
                onChange={e => setName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your name
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput2"
            >
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="name@example.com"
                onChange={e => setEmail(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your email
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput3"
            >
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="123-456-7890"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                onChange={e => setPhone(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid phone number
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput4"
            >
              <Form.Label>Select eggs by the dozen</Form.Label>
              <select
                onChange={e => setDozen(e.target.value)}
                required
                className="form-select"
                aria-label="Default select example"
              >
                <option
                  value=""
                  data-default=""
                >
                  choose eggs
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
              <Form.Control.Feedback type="invalid">
                Please select your eggs
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput5"
            >
              <Form.Label>Select drop-off point</Form.Label>
              <select
                required
                className="form-select"
                aria-label="Default select example"
                onChange={e => setLocation(e.target.value)}
              >
                <option
                  value=""
                  data-default=""
                >
                  choose location
                </option>
                <option value="Sunday Church 1:30PM">
                  Sunday - Church 1:30PM
                </option>
              </select>
              <Form.Control.Feedback type="invalid">
                Please select location
              </Form.Control.Feedback>
            </Form.Group>

            <p>Subtotal: ${(Number.parseFloat(dozen) * price).toFixed(2)}</p>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput6"
            >
              <Form.Label>Select payment type</Form.Label>
              <select
                disabled={!location}
                required
                className="form-select"
                aria-label="Default select example"
                onChange={e => setPayment(e.target.value)}
              >
                <option
                  value=""
                  data-default=""
                >
                  choose payment type
                </option>
                <option value="cash">Cash</option>
                <option value="card">Credit/Debit Card</option>
                <option
                  disabled
                  value="paypal"
                >
                  PayPal (Coming soon!)
                </option>
              </select>
              <Form.Control.Feedback type="invalid">
                Please select a payment type
              </Form.Control.Feedback>
            </Form.Group>

            {/*<Form.Group*/}
            {/*  className="mb-3"*/}
            {/*  required*/}
            {/*  controlId="exampleForm.ControlInput6"*/}
            {/*>*/}
            {/*  <div className="form-check">*/}
            {/*    <input*/}
            {/*      disabled={!location}*/}
            {/*      className="form-check-input"*/}
            {/*      type="radio"*/}
            {/*      name="flexRadioDefault"*/}
            {/*      id="flexRadioDefault2"*/}
            {/*      value="cash"*/}
            {/*      onChange={(e) => setPayment(e.target.value)}*/}
            {/*    />*/}
            {/*    <label className="form-check-label" htmlFor="flexRadioDefault2">*/}
            {/*      Cash*/}
            {/*    </label>*/}
            {/*  </div>*/}

            {/*  <div className="form-check">*/}
            {/*    <input*/}
            {/*      disabled={!location}*/}
            {/*      className="form-check-input"*/}
            {/*      type="radio"*/}
            {/*      name="flexRadioDefault"*/}
            {/*      id="flexRadioDefault1"*/}
            {/*      value="card"*/}
            {/*      onChange={(e) => setPayment(e.target.value)}*/}
            {/*    />*/}
            {/*    <label className="form-check-label" htmlFor="flexRadioDefault1">*/}
            {/*      Card*/}
            {/*    </label>*/}
            {/*  </div>*/}

            {/*  <div className="form-check">*/}
            {/*    <input*/}
            {/*      disabled*/}
            {/*      className="form-check-input"*/}
            {/*      type="radio"*/}
            {/*      name="flexRadioDefault"*/}
            {/*      id="flexRadioDefault1"*/}
            {/*      value="paypal"*/}
            {/*      onChange={(e) => setPayment(e.target.value)}*/}
            {/*    />*/}
            {/*    <label className="form-check-label" htmlFor="flexRadioDefault1">*/}
            {/*      Paypal (Coming soon!)*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*</Form.Group>*/}

            {submitButton}
            {/*<Button*/}
            {/*  disabled={!(name, email, phone, dozen, location, payment)}*/}
            {/*  className="w-100 mt-3"*/}
            {/*  variant="primary"*/}
            {/*  type="submit"*/}
            {/*>*/}
            {/*  Place Order*/}
            {/*</Button>*/}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Order;
