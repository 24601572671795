import React from 'react';
import firebase from 'firebase/app';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import 'firebase/firestore';
import app from '../utils/firebaseClient';

import { sendOrderSendEmail } from '../utils/emailer';

const ReceivedOrder = () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  const history = useHistory();

  useEffect(() => {
    if (id) {
      async function updatePaidStatus() {
        try {
          const orderRef = await app.firestore().collection('orders').doc(id);
          await orderRef.update({
            status: 'paid',
          });

          // Get customer info and send confirmation email
          const customerRef = await app
            .firestore()
            .collection('orders')
            .doc(id)
            .get();
          if (!customerRef.exists) {
            console.log('Customer order does not exist!');
          }
          const { name, email, dozen, location, payment } = customerRef.data();
          await sendOrderSendEmail(name, email, dozen, location, payment);

          // Update egg inventory count
          try {
            const eggRef = await app.firestore().collection('eggs').doc('egg');
            await eggRef.update({
              currentCount: firebase.firestore.FieldValue.increment(
                -dozen * 12
              ),
            });
          } catch (err) {
            console.log(err.stack);
          }
        } catch (err) {
          console.log(err.stack);
        }
      }
      updatePaidStatus().then(() => {
        setTimeout(() => {
          history.push('/');
        }, 3000);
      });
    }
  });

  return (
    <div>
      <h2>Thank you for your order!</h2>
    </div>
  );
};

export default ReceivedOrder;
