import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import Order from "./pages/Order";
import About from "./pages/About";
import Login from "./pages/Login";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import Layout from "./components/layout/Layout";
import ReceivedOrder from "./pages/ReceivedOrder";
import { AuthProvider } from "./utils/AuthProvider";
import PrivateRoute from "./components/PrivateRoute";
import { OrdersProvider } from "./utils/ordersProvider";

function App() {
  return (
    <AuthProvider>
      <OrdersProvider>
        <Layout>
          <div className="w-100" style={{ maxWidth: "960px" }}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/order" component={Order} />
              <Route path="/about" component={About} />
              <Route path="/login" component={Login} />
              <Route path="/contact" component={Contact} />
              <Route path="/received" component={ReceivedOrder} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
            </Switch>
          </div>
        </Layout>
      </OrdersProvider>
    </AuthProvider>
  );
}

export default App;
