import React from 'react';
import OrderDetail from './OrderDetail';
import { ListGroup } from 'react-bootstrap';

export default function OrderList(props) {
  const { orders } = props;

  const sortedOrders = orders.sort((a, b) => a.createdAt - b.createdAt);

  return (
    <ListGroup as="ul" className="mt-4">
      {sortedOrders.map(order => (
        <OrderDetail
          key={order.id}
          id={order.id}
          name={order.name}
          email={order.email}
          phone={order.phone}
          dozen={order.dozen}
          status={order.status}
          payment={order.payment}
          createdAt={order.createdAt}
          location={order.location}
        />
      ))}
    </ListGroup>
  );
}
