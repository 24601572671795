import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../../utils/AuthProvider';

//import classes from "./MainNavigation.module.css";

const MainNavigation = () => {
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error('Failed to logout', err);
    }
  }

  // Display ony when user is present
  let userLinks;
  if (currentUser) {
    userLinks = (
      <>
        <li>
          <Link className="nav-link" to="/dashboard">
            Dashboard
          </Link>
        </li>
        <li>
          <Button
            className="px-0 text-decoration-none"
            variant="link"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </li>
      </>
    );
  } else {
    userLinks = (
      <Link className="nav-link" to="/login">
        Login
      </Link>
    );
  }

  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          Aston Bros. Eggs
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/">
                Home
              </Link>
            </li>
            {/*<li className="nav-item">*/}
            {/*  <Link className="nav-link" to="/about">*/}
            {/*    About*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li className="nav-item">
              <Link className="nav-link" to="/order">
                Order
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                Contact
              </Link>
            </li>
            {userLinks}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;
