import React from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';

import { useOrders } from '../../utils/ordersProvider';

export default function OrderDetail(props) {
  const { fulfillOrder, deleteOrder } = useOrders();
  const { createdAt } = props;
  const humanReadableDate = createdAt.toDate().toLocaleString();

  function handleFulfillOrder() {
    fulfillOrder(props.id);
  }

  function handleDeleteOrder() {
    deleteOrder(props.id);
  }

  return (
    <ListGroup.Item>
      <Card>
        <div className="row align-items-start">
          <div className="col">
            <Card.Header as="h4" className="card-header">
              Order
            </Card.Header>
          </div>
          <div className="col">
            <Button variant="primary" onClick={handleFulfillOrder}>
              Complete
            </Button>
          </div>
          <div className="col">
            <Button variant="primary" onClick={handleDeleteOrder}>
              Delete
            </Button>
          </div>
        </div>
        <Card.Body>
          <div className="row align-items-start">
            <div className="col">
              <Card.Title>Name</Card.Title>
            </div>
            <div className="col">
              <Card.Text>{props.name}</Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Body>
          <div className="row align-items-start">
            <div className="col">
              <Card.Title>Email</Card.Title>
            </div>
            <div className="col">
              <Card.Text>{props.email}</Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Body>
          <div className="row align-items-start">
            <div className="col">
              <Card.Title>Phone Number</Card.Title>
            </div>
            <div className="col">
              <Card.Text>{props.phone}</Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Body>
          <div className="row align-items-start">
            <div className="col">
              <Card.Title>Dozens Ordered</Card.Title>
            </div>
            <div className="col">
              <Card.Text>{props.dozen}</Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Body>
          <div className="row align-items-start">
            <div className="col">
              <Card.Title>Payment Type</Card.Title>
            </div>
            <div className="col">
              <Card.Text>{props.payment}</Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Body>
          <div className="row align-items-start">
            <div className="col">
              <Card.Title>Payment Status</Card.Title>
            </div>
            <div className="col">
              <Card.Text>{props.status}</Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Body>
          <div className="row align-items-start">
            <div className="col">
              <Card.Title>Date Ordered</Card.Title>
            </div>
            <div className="col">
              <Card.Text>{humanReadableDate}</Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Body>
          <div className="row align-items-start">
            <div className="col">
              <Card.Title>Drop-off Location</Card.Title>
            </div>
            <div className="col">
              <Card.Text>{props.location}</Card.Text>
            </div>
          </div>
        </Card.Body>
      </Card>
    </ListGroup.Item>
  );
}
