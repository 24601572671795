import React from "react";
import { useHistory } from "react-router-dom";

import "./hero.styles.scss";

const Hero = () => {
  const history = useHistory();

  return (
    <section className="hero is-large is-info hero-image">
      <div className="hero-body">
        <p className="hero-title">Aston Brothers Eggs</p>
        <div className="shop-now-btn">
          <button
            className="button is-black"
            id="shop-now"
            onClick={() => history.push("/order")}
          >
            ORDER EGGS
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
