import { React, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useOrders } from '../utils/ordersProvider';
import OrderList from '../components/orders/OrderList';

import app from '../utils/firebaseClient';

const Dashboard = () => {
  const [eggs, setEggs] = useState('0');
  const { orders } = useOrders();

  // Add more eggs to the database
  const handleAddEggs = async e => {
    e.preventDefault();
    e.target.reset();

    try {
      const eggRef = await app.firestore().collection('eggs').doc('egg');
      await eggRef.update({
        currentCount: firebase.firestore.FieldValue.increment(+eggs),
      });
    } catch (err) {
      console.log(err.stack);
    }
  };

  return (
    <div style={{ marginTop: 70 }}>
      <form onSubmit={handleAddEggs}>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Got eggs?"
            aria-label="Got eggs?"
            aria-describedby="button-addon2"
            onChange={e => setEggs(e.target.value)}
          />
          <button className="btn btn-primary" type="submit" id="button-addon2">
            Submit
          </button>
        </div>
      </form>
      <h1>Current Orders</h1>
      <OrderList orders={orders} />
    </div>
  );
};

export default Dashboard;
