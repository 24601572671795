import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./firebaseClient";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    return auth.onIdTokenChanged((user) => {
      setCurrentUser(user);
      setIsLoading(false);
    });
  }, []);

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  const context = {
    currentUser,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={context}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
